export default function ContactPage(){

    return (
      <div className="text-sm leading-6">
        <p>Daniel Rusowicz</p>
        <a href="tel:+48602859677">+ 48 602 859 677</a>
        <p>
          <a href="mailto:kontakt@danielrusowicz.pl">
            kontakt@danielrusowicz.pl
          </a>
        </p>
      </div>
    );
}