import React from 'react'
import { Navigation, A11y } from 'swiper/modules';

import { Swiper, SwiperSlide } from 'swiper/react';
import { RealisationModel } from '../realisations';

const RealisationSlider = ({
    realisation,
    onImageClick
  }: {
    realisation: RealisationModel;
    onImageClick: () => void
  }) => {
  return (
    <div>
    <Swiper
      modules={[Navigation, A11y]}
      slidesPerView={"auto"}
      spaceBetween={50}
      navigation
      rewind={true}
      onSlideChange={() => console.log("slide change")}
    >
      {realisation.zdjecia.map((z) => (
        <SwiperSlide>
          <img
            onClick={onImageClick}
            src={"./realisations/" + z.url}
            alt={z.alt}
            className="cursor-pointer"
          />
        </SwiperSlide>
      ))}
    </Swiper>
  </div>
  )
}

export default RealisationSlider